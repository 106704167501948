/** @format */

import { useState, useEffect } from "react";
import { Fieldset } from "primereact/fieldset";
import { Slider } from "primereact/slider";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";

function MatchesPerRecord({ matchCountHandler, disabled }) {
  const [matchesPerRecord, setMatchesPerRecord] = useState(3);
  useEffect(() => {
    matchCountHandler(matchesPerRecord);
  }, [matchesPerRecord]);

  return (
    <div id="slideDiv1" className="grid">
      <div className="col-2 align-self-center ">
        <Tooltip target=".tooltip3" mouseTrack mouseTrackLeft={10}>
          <span>
            Highest number of candidate matches that can be returned for a given
            record per authority
          </span>
        </Tooltip>
        <label className="tooltip3 tooltip" htmlFor="slideDiv1">
          Maximum Candidate Matches per Record:
        </label>
      </div>
      <div className="col-9 align-self-center">
        <Tooltip
          target="#matchCount>.p-slider-handle"
          content={`${matchesPerRecord}`}
          position="top"
          event="hover"
        />
        <Slider
          id="matchCount"
          value={matchesPerRecord}
          onChange={(e) => {
            setMatchesPerRecord(e.value);
          }}
          min={1}
          max={10}
          disabled={disabled}
        />
      </div>
      <p className="col-1 align-self-center font-bold">{matchesPerRecord}</p>
    </div>
  );
}

function MinimumMatchScore({ minMatchScoreHandler, disabled }) {
  const [minMatchScore, setMinMatchScore] = useState(75);
  useEffect(() => {
    minMatchScoreHandler(minMatchScore * 0.01);
  }, [minMatchScore]);

  return (
    <div id="slideDiv2" className="grid align-self-center ">
      <Tooltip
        target=".tooltip4"
        position="right"
        mouseTrack
        mouseTrackLeft={10}>
        <span>
          Lowest possible match score that a candidate match must at least have
          for it to be returned.
        </span>
      </Tooltip>
      <div className="col-2 align-self-center ">
        <label className="tooltip tooltip4" htmlFor="slideDiv2">
          Minimum Match Score:
        </label>
      </div>
      <div className="col-9 align-self-center">
        <Tooltip
          target="#minSlider>.p-slider-handle"
          content={`${minMatchScore}%`}
          position="top"
          event="hover"
        />
        <Slider
          id="minSlider"
          value={minMatchScore}
          onChange={(e) => setMinMatchScore(e.value)}
          min={0}
          max={100}
          disabled={disabled}
        />
      </div>
      <p className="col-1 align-self-center font-bold">{minMatchScore}%</p>
    </div>
  );
}

function AutoMatchToggle({ autoMatchHandler, disabled }) {
  const [autoMatch, setAutoMatch] = useState(false);
  useEffect(() => {
    autoMatchHandler(autoMatch);
  }, [autoMatch]);

  return (
    <div className="p-d-flex p-mb-2">
      <label className="p-mr-2" htmlFor="autoMatchToggle">
        Automatically Reconcile Candidates with Highest Match Score:
      </label>
      <InputSwitch
        id="autoMatchToggle"
        checked={autoMatch}
        onChange={(e) => setAutoMatch(e.value)}
        disabled={disabled}
      />
    </div>
  );
}

function StepThree({
  enabled,
  minMatchScoreHandler,
  matchCountHandler,
  autoMatchHandler,
}) {
  const isDisabled = enabled.includes(3) ? false : true;
  return (
    <div className="p-mb-3">
      <Fieldset legend="Step 3: Configure Matches" disabled={isDisabled}>
        <div className="p-fluid">
          <MatchesPerRecord
            matchCountHandler={matchCountHandler}
            disabled={isDisabled}
          />
          <MinimumMatchScore
            minMatchScoreHandler={minMatchScoreHandler}
            disabled={isDisabled}
          />
          <AutoMatchToggle
            autoMatchHandler={autoMatchHandler}
            disabled={isDisabled}
          />
        </div>
      </Fieldset>
    </div>
  );
}

export default StepThree;
