/** @format */

import { Accordion, AccordionTab } from "primereact/accordion";

const Documentation = () => {
  return (
    <>
      <h1>
        This is a placeholder page for Quick Start Documentation on how to use
        VERSD
      </h1>
      <p>Will likely try to also make use of</p>
      <a href="https://github.com/gilbarbara/react-joyride">react-joyride</a>
      <p>for onboarding experience</p>

      <Accordion multiple>
        <AccordionTab header="{Question #1}">placeholder text</AccordionTab>
        <AccordionTab header="{Question #2}">placeholder textI</AccordionTab>
        <AccordionTab header="{Question #3}">placeholder textII</AccordionTab>
      </Accordion>
    </>
  );
};

export default Documentation;
