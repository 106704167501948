/** @format */

import http from "../http-common";

class JobDataService {
  create(data) {
    return http.post("/jobs", data);
  }

  getAll() {
    return http.get("/jobs");
  }

  get(id) {
    return http.get(`/jobs/${id}`);
  }

  update(id, data) {
    return http.put(`/jobs/${id}`, data);
  }

  delete(id) {
    return http.delete(`/jobs/${id}`);
  }

  deleteAll() {
    return http.delete(`/jobs`);
  }

  findByUsername(userId) {
    return http.get(`/jobs?userId=${userId}`);
  }
}

const JobService = new JobDataService();
export default JobService;
