/** @format */

import { useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";

export const SourceDataAutoComplete = ({
  sourceHeadings,
  rowID,
  headingsHandler,
  selectedOption,
  limit,
}) => {
  const headings = sourceHeadings;
  const [selectedHeadings, setSelectedHeadings] = useState(null);
  const [filteredHeadings, setFilteredHeadings] = useState(null);

  useEffect(() => {
    setSelectedHeadings(selectedOption);
  }, [selectedOption]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchHeadings = (event) => {
    setTimeout(() => {
      let _filteredHeadings;
      // Filters out already selected headings
      if (selectedHeadings) {
        _filteredHeadings = headings.filter(
          (item) => !selectedHeadings.includes(item),
        );
      } else {
        _filteredHeadings = [...headings];
      }
      // Filter based on search string
      if (event.query.trim().length) {
        _filteredHeadings = _filteredHeadings.filter((heading) => {
          return heading.toLowerCase().includes(event.query.toLowerCase());
        });
      }
      setFilteredHeadings(_filteredHeadings);
    }, 250);
  };

  if (limit) {
    return (
      <div className="card">
        <span className="p-fluid">
          <AutoComplete
            value={selectedHeadings}
            suggestions={filteredHeadings}
            completeMethod={searchHeadings}
            multiple
            selectionLimit={limit}
            spellCheck="false"
            dropdown
            onChange={(e) => {
              setSelectedHeadings(e.value);
              headingsHandler(rowID, e.value);
            }}
            aria-label="Source data headings"
          />
        </span>
      </div>
    );
  }
  return (
    <div className="card">
      <span className="p-fluid">
        <AutoComplete
          value={selectedHeadings}
          suggestions={filteredHeadings}
          completeMethod={searchHeadings}
          virtualScrollerOptions={{ itemSize: 38 }}
          forceSelection
          spellCheck="false"
          dropdown
          onChange={(e) => {
            setSelectedHeadings(e.value);
            headingsHandler(rowID, e.value);
          }}
          aria-label="Source data headings"
        />
      </span>
    </div>
  );
};
