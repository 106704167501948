/** @format */

import JobTable from "../components/job.component";

const ViewJobs = () => {
  return (
    <div>
      <JobTable />
    </div>
  );
};

export default ViewJobs;
