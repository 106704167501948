/** @format */

import { useState, useEffect, useRef } from "react";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { PickList } from "primereact/picklist";
import { Sidebar } from "primereact/sidebar";
import { TabView, TabPanel } from "primereact/tabview";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { SourceDataAutoComplete } from "./sourceDataAutoComplete.component";

const AUTHORITYCONFIG = require("../../assets/authority_config.json");

function AuthorityTypes({ authorityTypes, authorityTypeHandler, disabled }) {
  const authorityTypeOptions = [
    { name: "bibliographic", key: "B" },
    { name: "prosopographic", key: "P" },
    { name: "geospatial", key: "G" },
  ];

  const [selectedAuthorityTypes, setSelectedAuthorityTypes] =
    useState(authorityTypes);

  const onSelectedAuthorityTypeChange = (e) => {
    let _selectedAuthorityTypes = [...selectedAuthorityTypes];

    if (e.checked) {
      _selectedAuthorityTypes.push(e.value);
    } else {
      for (let i = 0; i < _selectedAuthorityTypes.length; i++) {
        const selectedCategory = _selectedAuthorityTypes[i];

        if (selectedCategory.key === e.value.key) {
          _selectedAuthorityTypes.splice(i, 1);
          break;
        }
      }
    }

    setSelectedAuthorityTypes(_selectedAuthorityTypes);
    authorityTypeHandler(_selectedAuthorityTypes.map((type) => type.name));
  };

  return (
    <div id="checkboxLayout">
      <Tooltip target=".tooltip1" position="left">
        <span>
          This will pre-populate list of available authorities such that the
          relevant authorities are selected
        </span>
      </Tooltip>
      <label
        htmlFor="checkboxLayout"
        className="p-col-12 p-md-2 tooltip1 tooltip">
        Type of Data:
      </label>

      {authorityTypeOptions.map((authType) => {
        return (
          <div key={authType.key} className="field-checkbox">
            <Checkbox
              inputId={authType.key}
              name="authType"
              value={authType}
              onChange={onSelectedAuthorityTypeChange}
              checked={selectedAuthorityTypes.some(
                (item) => item.key === authType.key,
              )}
              // TODO: disable G/P depending on available auths
              // disabled={authType.key === "G"}
              disabled={disabled}
            />
            <label htmlFor={authType.key}>{authType.name}</label>
          </div>
        );
      })}
    </div>
  );
}

function AuthorityPrioritization({ authorityTypes, authorityHandler }) {
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  useEffect(() => {
    const _source = [];

    for (const key in AUTHORITYCONFIG.authorities) {
      if (
        authorityTypes.some((item) =>
          AUTHORITYCONFIG.authorities[key].types.includes(item),
        )
      ) {
        _source.push(AUTHORITYCONFIG.authorities[key]);
      }
    }
    setSource(_source);

    // May not be desirable to clear target after effect, may want to filter list instead
    setTarget([]);
  }, [authorityTypes]);

  const onChange = (event) => {
    setSource(event.source);
    setTarget(event.target);
    authorityHandler(event.target);
  };

  const itemTemplate = (item) => {
    // NOTE: This can be expanded to include more info that can be used in formatting template
    return (
      <div className="authorityPickList">
        <span>{item.label}</span>
        <p className="">Best for: {item["recommended use"]}</p>
      </div>
    );
  };

  const targetItemTemplate = (item) => {
    return (
      <div className="authorityPickList">
        <span>
          {target.indexOf(item) + 1}. {item.label}
        </span>
        <p className="">Best for: {item["recommended use"]}</p>
      </div>
    );
  };

  return (
    <>
      <Tooltip target=".tooltip2" position="left">
        <span>
          Authority specifies which authority files your data should be compared
          against
        </span>
      </Tooltip>

      <label
        htmlFor="authoritiesBox tooltip2"
        className="p-col-12 p-md-2 tooltip2 tooltip">
        Authorities:{" "}
      </label>
      <PickList
        source={source}
        target={target}
        itemTemplate={itemTemplate}
        sourceHeader="Choose your Authorities"
        targetHeader="Prioritize your Authorities"
        sourceStyle={{ height: "342px" }}
        targetStyle={{ height: "342px" }}
        onChange={onChange}
        targetItemTemplate={targetItemTemplate}
      />
    </>
  );
}

function AuthoritySelection({
  sourceData,
  authorityHandler,
  disabled,
  dataHandler,
  mappingHandler,
}) {
  const [authorityTypes, setAuthorityTypes] = useState([]);
  const [authorities, setAuthorities] = useState([]);
  useEffect(() => {
    authorityHandler(
      authorities.map((element) => {
        return element.id;
      }),
    );
  }, [authorities]);

  return (
    <>
      <AuthorityTypes
        authorityTypeHandler={setAuthorityTypes}
        authorityTypes={authorityTypes}
        disabled={disabled}
      />
      <AuthorityPrioritization
        authorityTypes={authorityTypes}
        authorityHandler={setAuthorities}
      />
      <AuthorityMapping
        authorities={authorities}
        sourceData={sourceData}
        dataHandler={dataHandler}
        mappingHandler={mappingHandler}
      />
    </>
  );
}

function OverlayTable({
  authority,
  id,
  data,
  dataHandler,
  sourceDataHeadings,
  visibilityHandler,
  mappingHandler,
}) {
  function clearValues() {
    const temp = { ...data };
    for (const key in temp[authority.id]) {
      temp[authority.id][key].value = [];
    }
    dataHandler(temp);
  }

  function dropdownHandler(index, value) {
    const temp = { ...data };
    temp[authority.id][index].value = value;
    dataHandler(temp);
  }

  function sourceDataHeadingsBodyTemplate(rowData) {
    return (
      <SourceDataAutoComplete
        sourceHeadings={sourceDataHeadings}
        rowID={rowData.index}
        headingsHandler={dropdownHandler}
        selectedOption={rowData.value}
        limit={rowData.maximum}
      />
    );
  }

  function propertyBodyTemplate(rowData) {
    if (rowData?.maximum > 1) {
      return (
        <>
          <Tooltip target={"." + rowData.id} content={rowData.description} />
          <label className={`${rowData.id} tooltip`}>{rowData.label}(s)</label>
        </>
      );
    }

    return (
      <>
        <Tooltip target={"." + rowData.id} content={rowData.description} />
        <label className={`${rowData.id} tooltip`}>{rowData.label}</label>
      </>
    );
  }

  function evaluateMapping() {
    /* TODO: Evaluate the following
- has a min of 3 properties been selected for each authority
- how many properties have been selected?
- has every authority been filled out
*/

    // Creating a filtered mapping
    function createMapping() {
      const mapping = {};
      Object.entries(data).forEach((entry) => {
        const [key, value] = entry;
        const authMapping = [];
        value.forEach((element) => {
          if (element.value != null && element.value.length > 0) {
            authMapping.push({
              srcHeading: element.value,
              authHeading: element.id,
            });
          }
        });
        mapping[key] = authMapping;
      });

      return mapping;
    }
    const mapping = createMapping();
    mappingHandler(mapping);
  }

  return (
    <>
      <DataTable
        value={data[authority.id]}
        rowHover
        emptyMessage="No data found, contact a site administrator"
        // dataKey="id"
        removableSort
        scrollable
        scrollHeight="40em">
        <Column
          header={authority.label + " Properties"}
          headerStyle={{ textAlign: "center", fontWeight: "bold" }}
          body={propertyBodyTemplate}
          field={"label"}
          sortable
          className="p-dt-tooltip"
        />
        <Column
          headerStyle={{ textAlign: "center", fontWeight: "bold" }}
          header="Your Data's Columns"
          bodyStyle={{ textAlign: "center", width: "60%" }}
          body={sourceDataHeadingsBodyTemplate}
        />
      </DataTable>
      <div className="p-mb-3 p-d-flex p-jc-between">
        <Button
          label="Clear"
          className="p-m-3 p-button p-d-block p-mx-auto"
          onClick={clearValues}
        />
        <Button
          label="Use Suggested Mappings"
          className="p-m-3 p-button p-d-block p-mx-auto"
          disabled
          tooltip="Suggested mappings are currently not available"
        />
        <Button
          label="Done"
          className="p-m-3 p-button p-d-block p-mx-auto"
          onClick={() => {
            visibilityHandler(false);
            // todo: insert toast, confirming mapping within eval fx
            evaluateMapping();
          }}
        />
      </div>
    </>
  );
}

function AuthorityMapping({
  authorities,
  sourceData,
  dataHandler,
  mappingHandler,
}) {
  const toast = useRef(null);

  const [visibility, setVisibility] = useState(false);

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (authorities.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [authorities]);

  function initTableMapping(authorities) {
    let data = {};
    authorities.forEach((authority) => {
      const mapping = [];
      let index = 0;
      Object.entries(AUTHORITYCONFIG.fields).forEach((entry) => {
        const [fieldID, field] = entry;
        if (field.usage === "output") {
          return;
        }

        if (field.authorities.includes(authority.id)) {
          const full_field = { ...field };
          full_field["id"] = fieldID;
          full_field["value"] = [];
          full_field["index"] = index;
          mapping.push(full_field);
          index++;
        }
      });
      data[authority.id] = mapping;
    });

    return data;
  }

  const [tableData, setTableData] = useState({});
  useEffect(() => {
    setTableData(initTableMapping(authorities));
  }, [authorities]);

  const [sourceDataHeaders, setSourceDataHeaders] = useState([]);
  useEffect(() => {
    let headings = [];
    sourceData.forEach(
      (element) => (headings = headings.concat(Object.keys(element))),
    );
    let uniqueHeadings = [...new Set(headings)].sort(function (a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    setSourceDataHeaders(uniqueHeadings);
  }, [sourceData]);

  // TODO: Can I move this to parent? Or create a handler in the parent?
  const [mapping, setMapping] = useState({});
  useEffect(() => {
    const data = {};

    const remapRow = (row, mapping) => {
      console.group("Remapping Row");

      const newRow = {};
      const usedHeadings = []; // to store headings that have been mapped so that the rest oft the src data can be included

      // Going through mapping
      mapping.forEach((element) => {
        // handling multiple src headings to same auth heading
        // TODO: review when multiple is actually allowable
        if ("maximum" in AUTHORITYCONFIG.fields[element.authHeading]) {
          const tempRow = [];
          for (const heading of element.srcHeading) {
            if (heading in row) {
              tempRow.push(row[heading]);
              usedHeadings.push(heading);
            }
          }
          if (tempRow.length !== 0) {
            newRow[element.authHeading] = tempRow;
          }
        } else {
          const heading = element.srcHeading[0];
          if (heading in row && row[heading] !== "") {
            newRow[element.authHeading] = row[heading];
            usedHeadings.push(heading);
          }
        }
      });

      // TODO: Review this: adding extra fields from sourcedata
      // for (const key in row) {
      //   if (!usedHeadings.includes(key) && row[key] !== "") {
      //     newRow[`${key}`] = row[key];
      //   }
      // }
      console.groupEnd();

      return newRow;
    };

    const transformData = (authMap, authID) => {
      const doc = [];
      sourceData.forEach((element, index) => {
        const newRow = remapRow(element, authMap);
        //TODO: Need to double check if unique_id is actually unique throughout sourcedata
        if (!("unique_id" in newRow)) {
          newRow["unique_id"] = index.toString();
        }
        doc.push(newRow);
      });
      return doc;
    };

    for (const key in mapping) {
      const element = mapping[key];
      data[key] = JSON.stringify(transformData(element, key));
    }
    dataHandler(data);
    mappingHandler(mapping);
  }, [mapping]);

  return (
    <div className="p-fluid">
      <Toast ref={toast} />

      <Sidebar
        visible={visibility}
        fullScreen
        onHide={() => {
          setVisibility(false);
          // this.evaluateTableHandler();
        }}>
        <div className="p-fluid p-d-block p-mx-auto">
          <h3>Configure Mappings of Headings to Authority Properties</h3>
          <p>
            For the properties listed below, select the column name from your
            data that most align with the given property.
            <br />A minimum of 3 properties must be filled out.
          </p>
          <TabView>
            {authorities.map((authority, i) => {
              return (
                <TabPanel key={i} header={authority.label}>
                  <p>{authority["extra help"]}</p>
                  <OverlayTable
                    authority={authority}
                    id={i}
                    data={tableData}
                    dataHandler={setTableData}
                    sourceDataHeadings={sourceDataHeaders}
                    visibilityHandler={setVisibility}
                    mappingHandler={setMapping}></OverlayTable>
                </TabPanel>
              );
            })}
          </TabView>
        </div>
      </Sidebar>

      <div>
        <input
          type="button"
          value="Configure Mapping of Headings"
          onClick={() => {
            setVisibility(true);
          }}
          disabled={disabled}
          className="p-button p-d-block p-mx-auto"
        />
      </div>
    </div>
  );
}

const StepTwo = ({
  data,
  enabled,
  authorityHandler,
  dataHandler,
  mappingHandler,
}) => {
  const disabled = enabled.includes(2) ? false : true;

  return (
    <div className="p-mb-3">
      <Fieldset disabled={disabled} legend="Step 2: Select Authorities to use">
        <div className="p-fluid">
          <AuthoritySelection
            sourceData={data}
            disabled={disabled}
            authorityHandler={authorityHandler}
            dataHandler={dataHandler}
            mappingHandler={mappingHandler}
          />
        </div>
      </Fieldset>
    </div>
  );
};

export default StepTwo;
