/** @format */

import http from "../http-common";

class CountDataService {
  create(data) {
    return http.post("/matches", data);
  }

  getMatchCount(id) {
    return http.get(`/counts/matches/${id}`);
  }

  getCandidateCount(id) {
    return http.get(`/counts/candidates/${id}`);
  }

  getSourceRecordCount(id) {
    return http.get(`/counts/sourceRecords/${id}`);
  }

  getJobCount(id) {
    return http.get(`/counts/jobs/${id}`);
  }
}

const MatchService = new CountDataService();
export default MatchService;
