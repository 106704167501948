/** @format */

import { AuthContext } from "../contexts/AuthContext";
import { useContext, useRef } from "react";
import { Menubar } from "primereact/menubar";
import { useNavigate, Link } from "react-router-dom";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import { Menu } from "primereact/menu";

// TODO: Review props needed for menu
const AvatarIcon = ({ name, value, navigate, logout }) => {
  const img_url = `https://source.boringavatars.com/beam/100/${name}oool`;
  const menu = useRef(null);

  const items = [
    {
      id: "profile",
      label: "Profile",
      url: "/profile",
      icon: "pi pi-fw pi-user",
    },
    {
      id: "settings",
      label: "Preferences",
      url: "/preferences",
      icon: "pi pi-fw pi-cog",
      disabled: true,
    },
    {
      id: "logout",
      label: "Log Out",
      icon: "pi pi-fw pi-power-off",
      command: () => {
        logout(); //TODO:fix redirect, might be a keycloak config fix
      },
    },
  ];
  return (
    <>
      <Avatar
        image={img_url}
        shape="circle"
        size="large"
        className="p-overlay-badge"
        onClick={(event) => menu.current.toggle(event)}>
        {value ? <Badge value={value} /> : null}
      </Avatar>
      <Menu model={items} popup ref={menu} />;
    </>
  );
};

// TODO: add badge for number of requests/notifications
const UserMenu = ({ user, navigate, logout }) => {
  return (
    <AvatarIcon
      name={user.name}
      navigate={navigate}
      logout={logout}></AvatarIcon>
  );
};

const MainMenu = () => {
  const navigate = useNavigate();
  const { login, logout, isAuthenticated, user } = useContext(AuthContext);

  const authenticatedMenuModel = () => {
    return [
      {
        label: "Reconciliation Requests",
        items: [
          {
            label: "New Request",
            command: (e) => {
              navigate("/requests/create");
            },
          },
          {
            label: "My Requests",
            command: (e) => {
              navigate("/requests");
            },
          },
        ],
      },
      {
        label: "Help",
        icon: "pi pi-fw pi-question",
        command: (e) => {
          navigate("/help");
        },
      },
    ];
  };
  const unAuthenticatedMenuModel = () => {
    return [
      {
        label: "Documentation",
        icon: "pi pi-fw pi-question",
        command: (e) => {
          navigate("/help");
        },
      },
      {
        label: "Login",
        icon: "pi pi-fw pi-user",
        command: () => {
          login();
        },
      },
    ];
  };

  // Using cookies instead of context
  const items = isAuthenticated
    ? authenticatedMenuModel(logout)
    : unAuthenticatedMenuModel(login);

  const end = isAuthenticated ? (
    <UserMenu user={user} navigate={navigate} logout={logout} />
  ) : null;

  const start = (
    <Link to="/">
      <img
        alt="logo"
        src="/icon.png"
        onError={(e) =>
          (e.target.src =
            "https://lincsproject.ca/img/lincs-logo-(c-LINCS).png")
        }
        height="30px"
        className="p-mr-2"
      />
    </Link>
  );

  return <Menubar model={items} start={start} end={end} />;
};

export default MainMenu;
