/** @format */

import { useContext } from "react";
import UserRoleService from "../services/userRole.service";
import UserDataService from "../services/user.service";
import { Card } from "primereact/card";
import { AuthContext } from "../contexts/AuthContext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";

const UserStats = () => {
  return (
    <Card title="Your Activity">
      {/* <div className="cardBody"> */}
      <p>Number of Requests: 42</p>
      <p>Number of Matches: 341</p>
      <p>Number of Source Records: 567</p>
      {/* </div> */}
    </Card>
  );
};

const UserRanking = () => {
  return (
    <Card title="Your Ranking">
      <ol>
        <li>TS</li>
        <li>VW</li>
        .
        <br />
        .
        <br />
        .
        <br />
        <li value={13}>You</li>
      </ol>
      <i>You are only 14 Matches away from Ranking up!</i>
    </Card>
  );
};

const User = (props) => {
  const { user } = useContext(AuthContext);
  const acctURL = `${process.env.REACT_APP_KC_SERVER_AUTH_URL}realms/lincs/account/`;

  const img_url = `https://source.boringavatars.com/beam/100/${user?.name}`;

  return (
    <div className="user-info">
      <Card title="Account Information">
        <div className="cardBody">
          <div className="cardLeft">
            <img src={img_url} alt="Avatar" />
          </div>
          <div className="cardRight">
            <p>Name: {user?.name}</p>
            <p>Email: {user?.email}</p>
            <div className="block">
              <Button
                label="Manage Account"
                icon="pi pi-external-link"
                iconPos="right"
                text
                raised
                onClick={() => window.open(acctURL, "_blank")}
                className="font-bold px-5 py-3 white-space-nowrap "
              />
            </div>
          </div>
        </div>
      </Card>

      <UserStats />

      <UserRanking />
    </div>
  );
};

export default User;
