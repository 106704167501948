/** @format */

import http from "../http-common";

class UserRoleDataService {
  getAll() {
    return http.get("/users/roles");
  }

  get(userId, jobId) {
    return http.get(`/users/${userId}/roles/${jobId}`);
  }

  create(data) {
    return http.post("/users/roles", data);
  }

  update(userId, jobId, data) {
    return http.put(`/users/${userId}/roles/${jobId}`, data);
  }

  delete(userId, jobId) {
    return http.delete(`/users/${userId}/roles/${jobId}`);
  }

  deleteAll() {
    return http.delete(`/users/roles`);
  }

  findByUserId(userId) {
    return http.get(`/users/{userId}/roles`);
  }
}

const UserService = new UserRoleDataService();
export default UserService;
