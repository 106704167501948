/** @format */

import http from "../http-common";

class AuthorityMappingDataService {
  create(data) {
    return http.post("/authorityMappings", data);
  }

  getAll() {
    return http.get("/authorityMappings");
  }

  get(id) {
    return http.get(`/authorityMappings/${id}`);
  }

  update(id, data) {
    return http.put(`/authorityMappings/${id}`, data);
  }

  delete(id) {
    return http.delete(`/authorityMappings/${id}`);
  }

  deleteAll() {
    return http.delete(`/authorityMappings`);
  }

  findByJobID(jobId) {
    return http.get(`/authorityMappings?jobId=${jobId}`);
  }
}

const AuthorityMappingService = new AuthorityMappingDataService();
export default AuthorityMappingService;
