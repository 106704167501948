/** @format */

import http from "../http-common";

class CandidateDataService {
  create(data) {
    return http.post("/candidateMatches", data);
  }

  getAll() {
    return http.get("/candidateMatches");
  }

  get(id) {
    return http.get(`/candidateMatches/${id}`);
  }

  update(id, data) {
    return http.put(`/candidateMatches/${id}`, data);
  }

  delete(id) {
    return http.delete(`/candidateMatches/${id}`);
  }

  deleteAll() {
    return http.delete(`/candidateMatches`);
  }

  findByJob(jobId) {
    return http.get(`/candidateMatches?jobId=${jobId}`);
  }

  findByJobAndRecordID(jobId, recordId) {
    return http.get(`/candidateMatches?jobId=${jobId}&recordId=${recordId}`);
  }
}

const CandidateService = new CandidateDataService();
export default CandidateService;
