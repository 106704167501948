/** @format */
import { useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";

const NoPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div className="block text-center">
        <img
          src="https://images.unsplash.com/photo-1589652717521-10c0d092dea9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2340&q=80"
          alt=""
          height={"420px"}
          width={"520px"}
        />
        <h1>
          :( <br />
          The page you're looking for cannot be found.
        </h1>
        <p>
          The requested page "{location.pathname}" was not found on this server.
        </p>
        <div className="block text-center">
          <Button
            label="Return to Home Page"
            icon="pi pi-directions-alt"
            className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap "
            onClick={() => navigate("/")}
          />
        </div>
      </div>
    </>
  );
};

export default NoPage;
