/** @format */

import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import SourceRecordService from "../services/sourceRecord.service";

const Overview = ({ jobID }) => {
  const [sourceDataRows, setSourceDataRows] = useState([]);
  const [sourceDataCols, setSourceDataCols] = useState([]);

  // const productService = new ProductService();
  console.log(sessionStorage["userId"]);
  console.log(jobID);

  // LOAD data from Job only if the user is a creator or contributor of the dataset and that the job is ready
  useEffect(() => {
    SourceRecordService.findByJobID(jobID).then((data) => {
      const _rows = [];
      data.data.forEach((element) => {
        _rows.push(element.data);
      });
      setSourceDataRows(_rows);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let cols = [];
    let allFields = [];

    sourceDataRows.forEach(
      (element) => (allFields = allFields.concat(Object.keys(element))),
    );
    console.log(allFields);
    const uniqueHeadings = new Set(allFields);

    for (const item of uniqueHeadings) {
      cols.push({
        field: item,
        header: item,
      });
    }

    setSourceDataCols(cols);
  }, [sourceDataRows]);

  const getNamespace = (url) => {
    let namespaces = {
      viaf: "viaf",
      worldcat: "wc",
      wikidata: "wd",
    };

    for (const key in namespaces) {
      if (url.includes(key)) {
        return namespaces[key];
      }
    }
    return;
  };

  const hyperlinkTemplate = (url) => {
    let ns = getNamespace(url);
    if (ns) {
      var n = url.lastIndexOf("/");
      var suffix = url.substring(n + 1);
      return (
        <a href={url}>
          {ns}:{suffix}
        </a>
      );
    }
    return <a href={url}>{url}</a>;
  };

  const rowTemplate = (string) => {
    console.log({ string });
    // if (string.includes("http")) {
    //   return hyperlinkTemplate(string);
    // }
    return string;
  };

  const dynamicColumns = sourceDataCols.map((col, i) => {
    if (col.field !== "unique_id") {
      return (
        <Column
          key={col.field}
          style={{ width: "150px" }}
          field={col.field}
          reorderable
          sortable
          header={col.header}
          // body={()=>rowTemplate()}
        />
      );
    }
  });

  return (
    <div className="overview">
      <div className="overview-table">
        <DataTable
          value={sourceDataRows}
          stripedRows
          // responsiveLayout="scroll"
          resizableColumns
          // columnResizeMode="fit"
          scrollable
          scrollDirection="both"
          scrollHeight="50vw">
          <Column
            field="unique_id"
            header="Record ID"
            alignFrozen="left"
            style={{ width: "150px" }}
            frozen></Column>
          {dynamicColumns}
          <Column
            field="match status"
            header="Reconciliation Status"
            alignFrozen="right"
            style={{ width: "250px" }}
            frozen></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Overview;
