/** @format */

import { useState, useRef } from "react";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast";
import { usePapaParse } from "react-papaparse";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
const JobNameInput = ({ nameHandler }) => {
  const nameInput = useRef(); // may not need this value

  const handleNameInput = (e) => {
    const name = nameInput.current.value;
    if (name === "") return;
    nameHandler(name);
  };

  return (
    <div className="p-mb-2">
      <label htmlFor="jobname" className="p-col-12 p-md-2">
        Request Name:{" "}
      </label>
      <InputText
        id="jobname"
        type="text"
        required={true}
        ref={nameInput}
        onChange={handleNameInput}
      />
    </div>
  );
};

/**
 * Validating JSON data
 * @param  {string} item
 */
const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    alert("Pasted data is invalid");
    return false;
  }

  if (typeof item === "object" && item !== null) {
    alert("Pasted data has been uploaded");
    return true;
  } else {
    const errorMsg = `Unable to process pasted data due to invalid JSON Syntax`;
    return false;
  }
};

// TODO: Update this function
const PasteData = () => {
  const dataInput = useRef(); // may not need this value

  return (
    <div className="button-wrap">
      <InputTextarea
        type="text"
        rows="4"
        required={true}
        ref={dataInput}
        onChange={(e) => {}}
      />
      {/* for saving the data that has been pasted it does not auto validate */}
      <input
        type="button"
        className={
          "p-button p-d-block p-mx-auto " //+ this.state.stateStyleButton
        }
        value="Save / Validate"
        onClick={(e) => {
          if (isJson(dataInput.current.value)) {
            // Data handler?
          }
        }}
      />
    </div>
  );
};

const UploadData = ({ dataHandler }) => {
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const UploadFileHandler = ({ files }) => {
    var file = files[0];
    var data = null;
    var reader = new FileReader();
    const { readString } = usePapaParse();

    reader.onloadend = function (e) {
      data = reader.result;
      if (file.type === "application/json") {
        try {
          data = JSON.parse(data);
          dataHandler.dataHandler(data);
        } catch (e) {
          data = "";

          const errorMsg = `Unable to process ${file.name} due to invalid JSON syntax`;
          toast.current.show({
            severity: "error",
            summary: "File upload failed",
            detail: errorMsg,
            sticky: true,
          });
          console.warn(e);
        }
      } else if (file.type === "text/csv") {
        data = readString(data, { header: true });

        if (data.errors.length) {
          var errors = data.errors.map((o) => o.message).join("\n");
          const errorMsg = `Unable to process ${file.name} due to the following errors:\n ${errors}`;
          data = "";

          toast.current.show({
            severity: "error",
            summary: "File upload failed",
            detail: errorMsg,
            sticky: true,
          });
        } else {
          dataHandler.dataHandler(data.data);
        }
      }
    };
    reader.readAsText(file);
  };

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  };

  const onTemplateRemove = (file, callback) => {
    const errorMsg = `${file.name} has been deleted`;
    toast.current.show({
      severity: "error",
      summary: "File Deleted",
      detail: errorMsg,
      sticky: true,
    });
    setTotalSize(0);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formattedValue =
      fileUploadRef && fileUploadRef.current
        ? fileUploadRef.current.formatSize(totalSize)
        : "0 B";

    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
        }}>
        {chooseButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formattedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: "10rem", height: "12px" }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: "40%" }}>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag value={props.formatSize} className="px-3 py-2" />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file p-2"
          style={{
            fontSize: "5em",
            borderRadius: "50%",
            backgroundColor: "var(--surface-b)",
            color: "var(--surface-d)",
          }}></i>
        <span
          style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }}>
          Drag and Drop CSV/JSON File Here
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-folder",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
    color: "var(--primary-color)",
  };
  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    iconOnly: true,
    className:
      "custom-cancel-btn p-button-danger p-button-rounded p-button-outlined",
  };

  return (
    <div className="button-wrap">
      <Toast ref={toast} />
      <FileUpload
        name="uploaded"
        customUpload={true}
        uploadHandler={UploadFileHandler}
        auto
        ref={fileUploadRef}
        maxFileSize={100000}
        accept=".csv,.json"
        multiple={false}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
        chooseOptions={chooseOptions}
        cancelOptions={cancelOptions}
        onRemove={(e) => {
          const errorMsg = `${e.file.name} has been deleted`;
          toast.current.show({
            severity: "error",
            summary: "File Deleted",
            detail: errorMsg,
          });
          // TODO: update enabled with with all steps disabled
        }}
      />
      <p>Acceptable formats: CSV, JSON</p>
    </div>
  );
};

const DataSelection = (dataHandler) => {
  const [dataSource, setDataSource] = useState("upload");

  return (
    <div className="p-mt-3" id="radioLayout">
      <label htmlFor="radioLayout" className="p-col-12 p-md-2">
        Data Source:{" "}
      </label>
      <div>
        <div className="p-d-flex">
          <div className="p-field-checkbox">
            <RadioButton
              inputId="dataSource1"
              name="dataSource1"
              value="upload"
              onChange={(e) => {
                setDataSource(e.value);
              }}
              checked={dataSource === "upload"}
              className="p-mr-2 p-mb-2"
            />
            <label className="p-mr-2 p-mb-2" htmlFor="dataSource1">
              Upload File
            </label>
          </div>
          <div className="p-field-checkbox">
            <RadioButton
              inputId="dataSource2"
              name="dataSource2"
              value="paste"
              onChange={(e) => {
                setDataSource(e.value);
              }}
              checked={dataSource === "paste"}
              className="p-mb-2"
            />
            <label className="p-mr-2 p-mb-2" htmlFor="dataSource2">
              Paste in JSON
            </label>
          </div>
        </div>
      </div>

      {dataSource === "upload" && <UploadData dataHandler={dataHandler} />}
      {dataSource === "paste" && <PasteData />}
    </div>
  );
};

const StepOne = ({ nameHandler, dataHandler }) => {
  return (
    <div className="p-mb-3">
      <Fieldset legend="Step 1: Upload Data">
        <div className="p-fluid">
          <JobNameInput nameHandler={nameHandler} />
          <DataSelection dataHandler={dataHandler} />
        </div>
      </Fieldset>
    </div>
  );
};

export default StepOne;
