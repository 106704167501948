/** @format */

import { createContext, useEffect, useState } from "react";
import Keycloak from "keycloak-js";

var authURL = process.env.REACT_APP_KC_SERVER_AUTH_URL
  ? process.env.REACT_APP_KC_SERVER_AUTH_URL
  : "https://keycloak.dev.lincsproject.ca/";

const keycloakConfig = {
  realm: "lincs",
  url: authURL,
  clientId: "versd-ui",
};
const keycloak = new Keycloak(keycloakConfig);

const AuthContext = createContext({
  isAuthenticated: false,
  user: null,
  token: null,
  login: () => {},
  logout: () => {},
  register: () => {},
});

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useState(
    localStorage.getItem("versd-keycloak-token"),
  );
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("versd-keycloak-user")),
  );

  useEffect(() => {
    const token = localStorage.getItem("versd-keycloak-token");

    if (token) {
      setToken(token);
      setIsAuthenticated(true);

      keycloak
        .init({
          token: token,
          onLoad: "check-sso",
          silentCheckSsoRedirectUri: window.location.origin + "/profile",
        })
        .then((authenticated) => {
          setIsAuthenticated(authenticated);
        })
        .catch((error) => {
          console.error(`Failed to initialize Keycloak: ${error}`);
        });
    } else {
      keycloak
        .init({
          onLoad: "check-sso",
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.html",

          // checkLoginIframe: true, // REVIEW: not sure what effect this may have
        })
        .then((authenticated) => {
          setIsAuthenticated(authenticated);
          if (authenticated) {
            setUser(keycloak.authenticated ? keycloak.tokenParsed : null);
            setToken(keycloak.token);
            localStorage.setItem(
              "versd-keycloak-user",
              JSON.stringify(keycloak.tokenParsed),
            );
            localStorage.setItem("versd-keycloak-token", keycloak.token);
          }
        })
        .catch((error) => {
          console.error(`Failed to initialize Keycloak: ${error}`);
        });
    }
  }, []);

  const login = () => {
    keycloak.login();
  };
  const register = () => {
    keycloak.register();
  };

  const logout = (url) => {
    setToken(null);
    setUser(null);
    localStorage.removeItem("versd-keycloak-token");
    localStorage.removeItem("versd-keycloak-user");

    if (url) {
      keycloak.logout({ redirectUri: url }); // TODO: Test if this is working in prod
    } else {
      keycloak.logout();
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        token,
        login,
        logout,
        register,
        isAuthenticated,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
