/** @format */

import http from "../http-common";

class SourceRecordDataService {
  create(data) {
    return http.post("/sourceRecords", data);
  }

  getAll() {
    return http.get("/sourceRecords");
  }

  get(id) {
    return http.get(`/sourceRecords/${id}`);
  }

  update(id, data) {
    return http.put(`/sourceRecords/${id}`, data);
  }

  delete(id) {
    return http.delete(`/sourceRecords/${id}`);
  }

  deleteAll() {
    return http.delete(`/sourceRecords`);
  }

  findByJobID(jobId) {
    return http.get(`/sourceRecords?jobId=${jobId}`);
  }
}

const SourceRecordService = new SourceRecordDataService();
export default SourceRecordService;
