/** @format */

import { useLocation, useNavigate } from "react-router-dom";
import { BreadCrumb } from "primereact/breadcrumb";

const Breadcrumbs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const paths = location.pathname.split("/").filter((p) => p);
  const labels = {
    requests: "My Requests",
    create: "New",
    profile: "My Profile",
    overview: "Overview",
    quickmatch: "Quick Match",
    help: "Documentation",
  };
  const home = { icon: "pi pi-home", url: "/" };

  const items = paths.map((path, index) => {
    const pathTo = `/${paths.slice(0, index + 1).join("/")}`;
    return {
      label: labels[path] || path,
      url: pathTo,
      onClick: (event) => handleClick(event, pathTo),
    };
  });
  if (items.length > 0) {
    return <BreadCrumb model={items} home={home} />;
  }
  return <></>;
};
export default Breadcrumbs;
