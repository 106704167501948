/** @format */
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Button } from "primereact/button";
import NoPage from "./404";
const ForbiddenPage = () => {
  const { login, isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <NoPage />;
  }

  return (
    <div className="card-container">
      <h1 className="block text-center">
        You are currently not authorized to access that page
      </h1>
      <p className="block text-center">Please Log in to proceed</p>
      <div className="block text-center">
        <Button
          label="Log in to Get Started"
          icon="pi pi-play"
          className="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap "
          onClick={login}
        />
      </div>
    </div>
  );
};

export default ForbiddenPage;
