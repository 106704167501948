/** @format */

import User from "../components/user.component";
const AccountInfo = () => {
  return (
    <div>
      <User />
    </div>
  );
};

export default AccountInfo;
