/** @format */

import React, { useEffect, useState } from "react";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import { AppRouter } from "./routes";

import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./css/theme.css";
import "./css/App.css";

const App = () => {
  return (
    <AuthProvider>
      <div className="App surface-50">
        <AppRouter />
      </div>
    </AuthProvider>
  );
};
export default App;
