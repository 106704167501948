/** @format */

import Overview from "../components/overview.component";
import { useParams } from "react-router-dom";

const OverviewPage = () => {
  let params = useParams();
  return (
    <div>
      <Overview jobID={params.requestID} />
    </div>
  );
};

export default OverviewPage;
