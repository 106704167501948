/** @format */

import http from "../http-common";

class NSSIDataService {
  submitJob(data) {
    return http.post("/nssi/submit", data);
  }

  getJobStatus(id) {
    return http.get(`/nssi/status/${id}`);
  }

  getJobResults(id) {
    return http.get(`/nssi/results/${id}`);
  }

  //   TODO: create fx to run requests in parallel
  getJobStatuses(ids) {
    return http.get("/nssi/status", ids);
  }

  getResults(id) {
    return http.get("/nssi/results", id);
  }

  getToken() {
    return http.get("/nssi/token");
  }
}

const NssiService = new NSSIDataService();
export default NssiService;
