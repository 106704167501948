/** @format */

import QuickMatch from "../components/quickmatch.component";

import { useParams } from "react-router-dom";

const QuickMatchPage = () => {
  let params = useParams();
  return (
    <div>
      <QuickMatch jobID={params.requestID} />
    </div>
  );
};

export default QuickMatchPage;
