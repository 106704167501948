/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { useContext } from "react";
import MainMenu from "../layouts/mainMenu";
import ForbiddenPage from "../pages/403";
import NoPage from "../pages/404";
import HomePage from "../pages/homepage";
import NewJob from "../pages/createRequest";
import AccountInfo from "../pages/accountInfo";
import ViewJobs from "../pages/viewJobs";
import Documentation from "../pages/help";
import OverviewPage from "../pages/overviewTable";
import QuickMatchPage from "../pages/quickMatch";
import Breadcrumbs from "../layouts/breadcrumbs";
const authenticatedPages = [
  {
    path: "/requests/create",
    component: <NewJob />,
  },
  {
    path: "/requests",
    component: <ViewJobs />,
  },
  {
    path: "/profile",
    component: <AccountInfo />,
  },
  {
    path: "/requests/:requestID/overview",
    component: <OverviewPage />,
  },
  {
    path: "/requests/:requestID/quickmatch",
    component: <QuickMatchPage />,
  },
];

export const AppRouter = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <BrowserRouter>
      <MainMenu />
      <Breadcrumbs />

      <main>
        <Routes>
          <Route path="/*" element={<NoPage />} />
          <Route path="/403" element={<ForbiddenPage />} />
          <Route path="/404/:path" element={<NoPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/help" element={<Documentation />} />

          {authenticatedPages.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              element={
                <RequireAuth authenticated={isAuthenticated}>
                  {page.component}
                </RequireAuth>
              }
            />
          ))}
        </Routes>
      </main>
    </BrowserRouter>
  );
};

const RequireAuth = ({ children, authenticated }) => {
  // return token ? children : <ForbiddenPage />; //Checking cookie instead of context
  return authenticated ? children : <ForbiddenPage />;
};

// TODO: require specific roles
const isAuthorized = (keycloak) => {
  let roles = ["admin"];
  if (keycloak && roles) {
    return roles.some((r) => {
      const realm = keycloak.hasRealmRole(r);
      const resource = keycloak.hasResourceRole(r);
      return realm || resource;
    });
  }
  return false;
};
